<script lang="ts" context="module">
    import type {RadioChangeEventDetail} from "./controls/Radio.svelte";
    import type {SupportedLocale} from "../types";

    export interface LanguagePickerButtonsProps {
        entries: {
            href: string;
            label: string;
            locale: SupportedLocale;
        }[];
        label: string;
        selectedLocale: string;
    }
</script>

<script lang="ts">
    import Radio from "./controls/Radio.svelte";

    export let entries: LanguagePickerButtonsProps["entries"];
    export let label: LanguagePickerButtonsProps["label"];
    export let selectedLocale: LanguagePickerButtonsProps["selectedLocale"];

    function handleRadioChange(evt: CustomEvent<RadioChangeEventDetail>) {
        if (evt.detail.checked) {
            document.location.href = evt.detail.data;
        }
    }
</script>

<div class="language-picker">
    <p class="label">
        <strong>{label}</strong>
    </p>

    {#each entries as { href, locale, label } (locale)}
        <div>
            <Radio
                checked={locale === selectedLocale}
                class="capitalize"
                data={href}
                name="lang"
                on:change={handleRadioChange}
                value={locale}
            >
                {label}
            </Radio>
        </div>
    {/each}
</div>

<style>
    .language-picker {
        white-space: nowrap;
        color: var(--color-shade-100);
    }
    .label {
        margin-top: 0;
    }
</style>
