<script lang="ts" context="module">
    export interface RadioChangeEventDetail {
        checked: boolean;
        data?: any;
    }

    export interface RadioProps {
        name: string;
        id?: string;
        checked?: boolean;
        class?: string;
        data?: any;
        value?: string;
    }
</script>

<script lang="ts">
    import {combineClasses} from "@utils/component-utils";
    import {createEventDispatcher} from "svelte";

    let className: RadioProps["class"] = undefined;
    export {className as class};

    export let name: RadioProps["name"];
    export let value: RadioProps["value"] = undefined;
    export let id: RadioProps["id"] = undefined;
    export let checked: RadioProps["checked"] = false;
    export let data: RadioProps["data"] = undefined;

    const dispatch = createEventDispatcher();

    function handleChange(evt: Event) {
        const checked = (evt.target as any).checked;
        dispatch("change", {data, checked});
    }
</script>

<label class={combineClasses(["radio", className])}>
    <input type="radio" {name} {id} {checked} on:change={handleChange} {value} />
    <slot />
</label>

<style>
    .radio {
        --color-active: var(--color-primary-600);

        align-items: center;
        column-gap: var(--size-2);
        cursor: pointer;
        display: inline-flex;
    }

    input[type="radio"] {
        appearance: none;
        border: var(--size-px) solid var(--color-shade-500);
        border-radius: var(--radius-full);
        height: var(--size-4);
        margin: 0;
        width: var(--size-4);
    }

    input[type="radio"]:checked {
        background-color: transparent;
        border-color: var(--color-active);
        position: relative;
    }

    input[type="radio"]:checked::after {
        background-color: var(--color-active);
        border-radius: var(--radius-full);
        content: "";
        height: var(--size-2);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: var(--size-2);
    }

    input[type="radio"]:focus {
        outline: var(--focus-outline);
    }

    label:has(input[type="radio"]:checked) {
        color: var(--color-active);
    }
</style>
